var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-style',[_vm._v(" "+_vm._s(_vm.page.type == 6 && _vm.page.css)+" ")]),_c('div',{staticClass:"page-preview ct-home"},[_c('div',{staticClass:"container page-content position-relative"},[_c('div',{staticClass:"card-content pt-3 row accordion-page"},_vm._l((_vm.page.description),function(value,key){return _c('div',{key:key,staticClass:"ql-editor previewspage",staticStyle:{"width":"100%"}},[_c('b-card-body',{staticClass:"px-0"},_vm._l((value.description.blocks),function(itemBody,indexBody){return _c('div',{key:indexBody,staticClass:"w-100"},[(itemBody.type === 'paragraph')?_c('div',{staticClass:"item-data w-100",class:itemBody.tunes && itemBody.idChild
                    ? itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'toggle-block__item text-center'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'toggle-block__item text-right'
                      : 'toggle-block__item text-left'
                    : itemBody.tunes.anyTuneName.alignment === 'center'
                    ? 'text-center my-3'
                    : itemBody.tunes.anyTuneName.alignment === 'right'
                    ? 'text-right my-3'
                    : ' text-left my-3',attrs:{"id-child":itemBody.idChild,"hidden":itemBody.showDisplay === true ? true : false},domProps:{"innerHTML":_vm._s(itemBody.data.text)}}):_vm._e(),(itemBody.type === 'header')?_c('div',{staticClass:"item-data w-100",attrs:{"id-child":itemBody.idChild,"hidden":itemBody.showDisplay === true ? true : false}},[(itemBody.data.level === 1)?_c('h1',{staticClass:"mb-0",class:itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3',domProps:{"innerHTML":_vm._s(itemBody.data.text)}}):_vm._e(),(itemBody.data.level === 2)?_c('h2',{staticClass:"mb-0",class:itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3',domProps:{"innerHTML":_vm._s(itemBody.data.text)}}):_vm._e(),(itemBody.data.level === 3)?_c('h3',{staticClass:"mb-0",class:itemBody.tunes &&
                    itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : itemBody.idChild
                      ? 'toggle-block__item text-left'
                      : 'text-left my-3',domProps:{"innerHTML":_vm._s(itemBody.data.text)}}):_vm._e(),(itemBody.data.level === 4)?_c('h4',{staticClass:"mb-0",class:itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3',domProps:{"innerHTML":_vm._s(itemBody.data.text)}}):_vm._e(),(itemBody.data.level === 5)?_c('h5',{staticClass:"mb-0",class:itemBody.tunes &&
                    itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : itemBody.idChild
                      ? 'toggle-block__item text-left'
                      : 'text-left my-3',domProps:{"innerHTML":_vm._s(itemBody.data.text)}}):_vm._e(),(itemBody.data.level === 6)?_c('h6',{staticClass:"mb-0",class:itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3',domProps:{"innerHTML":_vm._s(itemBody.data.text)}}):_vm._e()]):_vm._e(),(itemBody.type === 'list')?_c('div',{staticClass:"item-data w-100",class:itemBody.idChild ? 'toggle-block__item text-left' : 'my-3',attrs:{"id-child":itemBody.idChild,"hidden":itemBody.showDisplay === true ? true : false}},[_c('ul',{staticClass:"w-100",staticStyle:{"list-style-type":"decimal"}},_vm._l((itemBody.data.items),function(itemList,indexList){return _c('li',{key:indexList,staticClass:"mb-2"},[_vm._v(" "+_vm._s(itemList)+" ")])}),0)]):_vm._e(),(itemBody.type === 'toggle')?_c('div',{staticClass:"item-data w-100",attrs:{"id-child":itemBody.idChild,"hidden":itemBody.showDisplay === true ? true : false}},[_c('div',{staticClass:"w-100 d-flex align-items-center toggle-block__selector",attrs:{"id":itemBody.id}},[_c('span',{staticClass:"icon-toggle-panel mr-2",on:{"click":function($event){return _vm.toggleBlock(itemBody)}}},[_c('i',{class:itemBody.data.status === 'open'
                          ? 'fas fa-caret-down'
                          : 'fas fa-caret-right'})]),_vm._v(" "+_vm._s(itemBody.data.text)+" ")])]):_vm._e(),(itemBody.type === 'code')?_c('div',{staticClass:"item-data w-100",class:itemBody.idChild ? 'toggle-block__item text-left' : 'my-3',attrs:{"id-child":itemBody.idChild,"hidden":itemBody.showDisplay === true ? true : false}},[_c('div',{domProps:{"innerHTML":_vm._s(itemBody.data.code)}})]):_vm._e(),(itemBody.type === 'raw')?_c('div',{staticClass:"item-data w-100 my-3",class:itemBody.idChild ? 'toggle-block__item text-left' : '',attrs:{"id-child":itemBody.idChild,"hidden":itemBody.showDisplay === true ? true : false}},[_c('div',{domProps:{"innerHTML":_vm._s(itemBody.data.html)}})]):_vm._e(),(itemBody.type === 'table')?_c('div',{staticClass:"item-data w-100",class:itemBody.idChild ? 'toggle-block__item text-left' : 'my-3',attrs:{"id-child":itemBody.idChild,"hidden":itemBody.showDisplay === true ? true : false}},[_c('table',{staticClass:"table table-bordered"},[_c('thead',[_c('tr',_vm._l((itemBody.data
                          .content[0]),function(itemTblTh,indexTblTh){return _c('th',{key:indexTblTh,staticClass:"border-bottom-0"},[_vm._v(" "+_vm._s(itemTblTh)+" ")])}),0)]),_c('tbody',[_c('tr',_vm._l((itemBody.data
                          .content[1]),function(itemTblTd,indexTblTd){return _c('td',{key:indexTblTd},[_vm._v(" "+_vm._s(itemTblTd)+" ")])}),0)])])]):_vm._e(),(itemBody.type === 'checklist')?_c('div',{staticClass:"item-data w-100",class:itemBody.idChild ? 'toggle-block__item text-left' : 'my-3',attrs:{"id-child":itemBody.idChild,"hidden":itemBody.showDisplay === true ? true : false}},_vm._l((itemBody.data.items),function(itemCheckbox,indexCheckbox){return _c('div',{key:indexCheckbox,staticClass:"custom-control custom-checkbox"},[_c('input',{staticClass:"custom-control-input",attrs:{"id":'checkbox-' + itemBody.id + indexCheckbox,"type":"checkbox","name":'checkbox-' + itemBody.id + indexCheckbox},domProps:{"checked":itemCheckbox.checked}}),_c('label',{staticClass:"custom-control-label ml-1",attrs:{"for":'checkbox-' + itemBody.id + indexCheckbox}},[_vm._v(" "+_vm._s(itemCheckbox.text)+" ")])])}),0):_vm._e(),(itemBody.type === 'warning')?_c('div',{staticClass:"item-data w-100",class:itemBody.idChild ? 'toggle-block__item text-left' : 'my-3',attrs:{"id-child":itemBody.idChild,"hidden":itemBody.showDisplay === true ? true : false}},[_c('div',{staticClass:"w-100 bg-warning p-3 text-white"},[_c('div',{staticClass:"w-100 font-weight-bold"},[_vm._v(" "+_vm._s(itemBody.data.title)+" ")]),_c('hr'),_c('div',{staticClass:"w-100"},[_vm._v(" "+_vm._s(itemBody.data.message)+" ")])])]):_vm._e(),(itemBody.type === 'quote')?_c('div',{staticClass:"item-data w-100",class:itemBody.idChild ? 'toggle-block__item text-left' : 'my-3',attrs:{"id-child":itemBody.idChild,"hidden":itemBody.showDisplay === true ? true : false}},[_c('blockquote',{staticClass:"otro-blockquote",class:itemBody.data.alignment === 'left'
                      ? 'text-left'
                      : 'text-right'},[_c('span',[_vm._v(" "+_vm._s(itemBody.data.caption)+" ")]),_vm._v(" "+_vm._s(itemBody.data.text)+" ")])]):_vm._e(),(itemBody.type === 'headerStyle')?_c('div',{staticClass:"item-data w-100",class:itemBody.tunes && itemBody.idChild
                    ? itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'toggle-block__item text-center'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'toggle-block__item text-right'
                      : 'toggle-block__item text-left'
                    : itemBody.tunes.anyTuneName.alignment === 'center'
                    ? 'text-center my-3'
                    : itemBody.tunes.anyTuneName.alignment === 'right'
                    ? 'text-right my-3'
                    : ' text-left my-3',attrs:{"id-child":itemBody.idChild,"hidden":itemBody.showDisplay === true ? true : false}},[_c('div',{staticClass:"titleStyle",class:itemBody.data.class},[_c('h1',{staticClass:"dataInput"},[_vm._v(" "+_vm._s(itemBody.data.text)+" ")])])]):_vm._e(),(itemBody.type === 'boxStylle')?_c('div',{staticClass:"item-data w-100",class:itemBody.tunes && itemBody.idChild
                    ? itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'toggle-block__item text-center'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'toggle-block__item text-right'
                      : 'toggle-block__item text-left'
                    : itemBody.tunes.anyTuneName.alignment === 'center'
                    ? 'text-center my-3'
                    : itemBody.tunes.anyTuneName.alignment === 'right'
                    ? 'text-right my-3'
                    : ' text-left my-3',attrs:{"id-child":itemBody.idChild,"hidden":itemBody.showDisplay === true ? true : false}},[_c('div',{staticClass:"boxStyle",class:itemBody.data.class},[(itemBody.data.title)?_c('span',{staticClass:"box-title px-2 border-0"},[_vm._v(_vm._s(itemBody.data.title))]):_vm._e(),_c('div',{staticClass:"border-0 shadow-none bg-transparent h-auto form-control p-2 dataInput"},[_vm._v(" "+_vm._s(itemBody.data.text)+" ")])])]):_vm._e(),(itemBody.type === 'delimiter')?_c('div',{staticClass:"item-data w-100",class:itemBody.idChild ? 'toggle-block__item text-left' : 'my-3',attrs:{"id-child":itemBody.idChild,"hidden":itemBody.showDisplay === true ? true : false}},[_c('div',{staticClass:"w-100 text-center ce-delimiter"})]):_vm._e(),(itemBody.type === 'image')?_c('div',{staticClass:"item-data w-100",class:itemBody.tunes && itemBody.idChild
                    ? itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'toggle-block__item text-center'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'toggle-block__item text-right'
                      : 'toggle-block__item text-left'
                    : itemBody.tunes.anyTuneName.alignment === 'center'
                    ? 'text-center my-3'
                    : itemBody.tunes.anyTuneName.alignment === 'right'
                    ? 'text-right my-3'
                    : ' text-left my-3',attrs:{"id-child":itemBody.idChild,"hidden":itemBody.showDisplay === true ? true : false}},[_c('img',{attrs:{"src":itemBody.data.file.url,"alt":"","width":"auto"}}),_c('h4',{staticClass:"mt-2"},[_vm._v(_vm._s(itemBody.data.caption))])]):_vm._e(),(itemBody.type === 'attaches')?_c('div',{staticClass:"item-data w-100",class:itemBody.idChild ? 'toggle-block__item text-left' : 'my-3',attrs:{"id-child":itemBody.idChild,"hidden":itemBody.showDisplay === true ? true : false}},[(!_vm.loadDataNote)?_c('ViewPdf',{attrs:{"pageNumber":_vm.lastPagePDF,"timeWatching":_vm.timeWatchingEmbed,"idLast":_vm.idLastPDF,"src":itemBody.data.file.url,"idComponent":itemBody.id}}):_vm._e()],1):_vm._e(),(
                  itemBody.type === 'embed' &&
                  itemBody.data.service === 'youtube'
                )?_c('div',{staticClass:"item-data w-100",class:itemBody.idChild ? 'toggle-block__item text-left' : 'my-3',attrs:{"id-child":itemBody.idChild,"hidden":itemBody.showDisplay === true ? true : false}},[_c('YoutubePlayer',{attrs:{"idElemYoutube":itemBody.id,"idLastYoutube":_vm.idLastEmbed,"timeWatching":_vm.timeWatchingEmbed,"src":itemBody.data.source,"heightPlayer":itemBody.data.height,"widthPlayer":itemBody.data.width}}),(itemBody.data.caption != null)?_c('h3',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(itemBody.data.caption)+" ")]):_vm._e()],1):_vm._e(),(
                  itemBody.type === 'embed' &&
                  itemBody.data.service === 'vimeo'
                )?_c('div',{staticClass:"item-data w-100",class:itemBody.idChild ? 'toggle-block__item text-left' : 'my-3',attrs:{"id-child":itemBody.idChild,"hidden":itemBody.showDisplay === true ? true : false}},[_c('VimeoPlayer',{attrs:{"idElemVimeo":itemBody.id,"idLastVimeo":_vm.idLastEmbed,"timeWatching":_vm.timeWatchingEmbed,"src":itemBody.data.embed,"heightPlayer":itemBody.data.height,"widthPlayer":itemBody.data.width}}),(itemBody.data.caption != null)?_c('h3',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(itemBody.data.caption)+" ")]):_vm._e()],1):_vm._e(),(itemBody.type === 'anyButton')?_c('div',{staticClass:"item-data w-100",class:itemBody.tunes && itemBody.idChild
                    ? itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'toggle-block__item text-center'
                      : itemBody.tunes &&
                        itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'toggle-block__item text-right'
                      : 'toggle-block__item text-left'
                    : itemBody.tunes.anyTuneName.alignment === 'center'
                    ? 'text-center my-3'
                    : itemBody.tunes.anyTuneName.alignment === 'right'
                    ? 'text-right my-3'
                    : ' text-left my-3',attrs:{"id-child":itemBody.idChild,"hidden":itemBody.showDisplay === true ? true : false}},[(itemBody.data.type === null)?_c('a',{class:itemBody.data.style,attrs:{"rel":"nofollow noindex noreferrer","href":itemBody.data.link}},[_vm._v(" "+_vm._s(itemBody.data.text)+" ")]):_vm._e(),(
                    itemBody.data.type !== null &&
                    (itemBody.data.style === 'roundButton' ||
                      itemBody.data.style === 'socialGlossySmooth' ||
                      itemBody.data.style === 'socialSquare')
                  )?_c('div',{staticClass:"w-100",class:itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'},[(itemBody.data.twitter !== null)?_c('a',{staticClass:"btn-social-circle btn-social-circle--twitter",attrs:{"href":itemBody.data.twitter}},[_c('i',{staticClass:"fab fa-twitter"})]):_vm._e(),(itemBody.data.facebook !== null)?_c('a',{staticClass:"btn-social-circle btn-social-circle--facebook",attrs:{"href":itemBody.data.facebook}},[_c('i',{staticClass:"fab fa-facebook-f"})]):_vm._e(),(itemBody.data.pocket !== null)?_c('a',{staticClass:"btn-social-circle btn-social-circle--pocket",attrs:{"href":itemBody.data.pocket}},[_c('i',{staticClass:"fab fa-get-pocket"})]):_vm._e(),(itemBody.data.feedly !== null)?_c('a',{staticClass:"btn-social-circle btn-social-circle--feedly",attrs:{"href":itemBody.data.feedly}},[_c('i',{staticClass:"fas fa-rss"})]):_vm._e()]):_vm._e(),(
                    itemBody.data.type !== null &&
                    (itemBody.data.style === 'socialGiza' ||
                      itemBody.data.style === 'socialSmartPhone')
                  )?_c('div',{staticClass:"w-100",class:itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'},[_c('div',{class:itemBody.data.style === 'socialGiza'
                        ? 'btn-social-giza'
                        : 'btn-social-phone'},[(itemBody.data.twitter !== null)?_c('a',{class:itemBody.data.style === 'socialGiza'
                          ? 'btn-social-giza-twitter'
                          : 'btn-social-phone-twitter ml-0 mr-3',attrs:{"href":"1"}},[_c('span',{staticClass:"fa-stack"},[_c('i',{staticClass:"fas fa-stack-2x",class:itemBody.data.style === 'socialGiza'
                              ? 'fa-certificate'
                              : 'fa-tablet-alt'}),_c('i',{staticClass:"fab fa-twitter fa-stack-1x"})])]):_vm._e(),(itemBody.data.facebook !== null)?_c('a',{class:itemBody.data.style === 'socialGiza'
                          ? 'btn-social-giza-facebook'
                          : 'btn-social-phone-facebook ml-0 mr-3',attrs:{"href":"3535"}},[_c('span',{staticClass:"fa-stack"},[_c('i',{staticClass:"fas fa-stack-2x",class:itemBody.data.style === 'socialGiza'
                              ? 'fa-certificate'
                              : 'fa-tablet-alt'}),_c('i',{staticClass:"fab fa-facebook-f fa-stack-1x"})])]):_vm._e(),(itemBody.data.pocket !== null)?_c('a',{class:itemBody.data.style === 'socialGiza'
                          ? 'btn-social-giza-pocket'
                          : 'btn-social-phone-pocket ml-0 mr-3',attrs:{"href":"5467"}},[_c('span',{staticClass:"fa-stack"},[_c('i',{staticClass:"fas fa-stack-2x",class:itemBody.data.style === 'socialGiza'
                              ? 'fa-certificate'
                              : 'fa-tablet-alt'}),_c('i',{staticClass:"fab fa-get-pocket fa-stack-1x"})])]):_vm._e(),(itemBody.data.feedly !== null)?_c('a',{class:itemBody.data.style === 'socialGiza'
                          ? 'btn-social-giza-feedly'
                          : 'btn-social-phone-feedly ml-0 mr-3',attrs:{"href":"1"}},[_c('span',{staticClass:"fa-stack"},[_c('i',{staticClass:"fas fa-stack-2x",class:itemBody.data.style === 'socialGiza'
                              ? 'fa-certificate'
                              : 'fa-tablet-alt'}),_c('i',{staticClass:"fas fa-rss fa-stack-1x"})])]):_vm._e()])]):_vm._e(),(
                    itemBody.data.type !== null &&
                    (itemBody.data.style === 'socialFlat' ||
                      itemBody.data.style === 'socialIsometric')
                  )?_c('div',{staticClass:"w-100",class:itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'},[_c('div',[_c('a',{class:itemBody.data.style === 'socialFlat'
                          ? 'btn-social-flat'
                          : 'btn-social-isometric',attrs:{"href":"1"}},[_c('span',{class:itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat-icon btn-social-isometric-icon--twitter'
                            : 'btn-social-isometric-icon btn-social-isometric-icon--twitter'},[_c('i',{staticClass:"fab fa-twitter"})]),_c('span',{class:itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat-text'
                            : 'btn-social-isometric-text'},[_vm._v("TWEET")])])]),_c('div',[_c('a',{class:itemBody.data.style === 'socialFlat'
                          ? 'btn-social-flat'
                          : 'btn-social-isometric',attrs:{"href":"1"}},[_c('span',{class:itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat-icon btn-social-isometric-icon--facebook'
                            : 'btn-social-isometric-icon btn-social-isometric-icon--facebook'},[_c('i',{staticClass:"fab fa-facebook"})]),_c('span',{class:itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat-text'
                            : 'btn-social-isometric-text'},[_vm._v("TWEET")])])]),_c('div',[_c('a',{class:itemBody.data.style === 'socialFlat'
                          ? 'btn-social-flat'
                          : 'btn-social-isometric',attrs:{"href":"1"}},[_c('span',{class:itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat-icon btn-social-isometric-icon--pocket'
                            : 'btn-social-isometric-icon btn-social-isometric-icon--pocket'},[_c('i',{staticClass:"fab fa-get-pocket"})]),_c('span',{class:itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat-text'
                            : 'btn-social-isometric-text'},[_vm._v("TWEET")])])]),_c('div',[_c('a',{class:itemBody.data.style === 'socialFlat'
                          ? 'btn-social-flat'
                          : 'btn-social-isometric',attrs:{"href":"1"}},[_c('span',{class:itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat-icon btn-social-isometric-icon--feedly'
                            : 'btn-social-isometric-icon btn-social-isometric-icon--feedly'},[_c('i',{staticClass:"fas fa-rss"})]),_c('span',{class:itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat-text'
                            : 'btn-social-isometric-text'},[_vm._v("TWEET")])])])]):_vm._e()]):_vm._e()])}),0)],1)}),0),_c('div',{staticClass:"card-content pt-3 row align-items-center justify-content-center w-100 content-btn"},[_c('button',{staticClass:"float-right button-back-dashboard back-btn",on:{"click":function($event){return _vm.returnPage()}}},[_vm._v(" 戻る ")])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }