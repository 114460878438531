<template>
  <div>
    <v-style> {{ page.type == 6 && page.css }} </v-style>
    <div class="page-preview ct-home">
      <div class="container page-content position-relative">
        <div class="card-content pt-3 row accordion-page">
          <div
            class="ql-editor previewspage"
            v-for="(value, key) in page.description"
            style="width: 100%"
            :key="key"
          >
            <!-- <b-collapse accordion="my-accordion" > -->
            <b-card-body class="px-0">
              <div
                class="w-100"
                v-for="(itemBody, indexBody) in value.description.blocks"
                :key="indexBody"
              >
                <div
                  class="item-data w-100"
                  v-if="itemBody.type === 'paragraph'"
                  v-html="itemBody.data.text"
                  :class="
                    itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                ></div>
                <div
                  class="item-data w-100"
                  v-if="itemBody.type === 'header'"
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <h1
                    v-if="itemBody.data.level === 1"
                    class="mb-0"
                    :class="
                      itemBody.tunes && itemBody.idChild
                        ? itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'toggle-block__item text-center'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'toggle-block__item text-right'
                          : 'toggle-block__item text-left'
                        : itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'text-center my-3'
                        : itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'text-right my-3'
                        : ' text-left my-3'
                    "
                    v-html="itemBody.data.text"
                  ></h1>
                  <h2
                    v-if="itemBody.data.level === 2"
                    class="mb-0"
                    :class="
                      itemBody.tunes && itemBody.idChild
                        ? itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'toggle-block__item text-center'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'toggle-block__item text-right'
                          : 'toggle-block__item text-left'
                        : itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'text-center my-3'
                        : itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'text-right my-3'
                        : ' text-left my-3'
                    "
                    v-html="itemBody.data.text"
                  ></h2>
                  <h3
                    v-if="itemBody.data.level === 3"
                    class="mb-0"
                    :class="
                      itemBody.tunes &&
                      itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'text-center my-3'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'text-right my-3'
                        : itemBody.idChild
                        ? 'toggle-block__item text-left'
                        : 'text-left my-3'
                    "
                    v-html="itemBody.data.text"
                  ></h3>
                  <h4
                    v-if="itemBody.data.level === 4"
                    class="mb-0"
                    :class="
                      itemBody.tunes && itemBody.idChild
                        ? itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'toggle-block__item text-center'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'toggle-block__item text-right'
                          : 'toggle-block__item text-left'
                        : itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'text-center my-3'
                        : itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'text-right my-3'
                        : ' text-left my-3'
                    "
                    v-html="itemBody.data.text"
                  ></h4>
                  <h5
                    v-if="itemBody.data.level === 5"
                    class="mb-0"
                    :class="
                      itemBody.tunes &&
                      itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'text-center my-3'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'text-right my-3'
                        : itemBody.idChild
                        ? 'toggle-block__item text-left'
                        : 'text-left my-3'
                    "
                    v-html="itemBody.data.text"
                  ></h5>
                  <h6
                    v-if="itemBody.data.level === 6"
                    class="mb-0"
                    :class="
                      itemBody.tunes && itemBody.idChild
                        ? itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'toggle-block__item text-center'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'toggle-block__item text-right'
                          : 'toggle-block__item text-left'
                        : itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'text-center my-3'
                        : itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'text-right my-3'
                        : ' text-left my-3'
                    "
                    v-html="itemBody.data.text"
                  ></h6>
                </div>
                <div
                  class="item-data w-100"
                  v-if="itemBody.type === 'list'"
                  :class="
                    itemBody.idChild ? 'toggle-block__item text-left' : 'my-3'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <ul class="w-100" style="list-style-type: decimal">
                    <li
                      v-for="(itemList, indexList) in itemBody.data.items"
                      :key="indexList"
                      class="mb-2"
                    >
                      {{ itemList }}
                    </li>
                  </ul>
                </div>
                <div
                  class="item-data w-100"
                  v-if="itemBody.type === 'toggle'"
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <div
                    class="w-100 d-flex align-items-center toggle-block__selector"
                    :id="itemBody.id"
                  >
                    <span
                      class="icon-toggle-panel mr-2"
                      @click="toggleBlock(itemBody)"
                    >
                      <i
                        :class="
                          itemBody.data.status === 'open'
                            ? 'fas fa-caret-down'
                            : 'fas fa-caret-right'
                        "
                      ></i>
                    </span>
                    {{ itemBody.data.text }}
                  </div>
                </div>
                <div
                  class="item-data w-100"
                  v-if="itemBody.type === 'code'"
                  :class="
                    itemBody.idChild ? 'toggle-block__item text-left' : 'my-3'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <div v-html="itemBody.data.code"></div>
                </div>
                <div
                  class="item-data w-100 my-3"
                  v-if="itemBody.type === 'raw'"
                  :class="
                    itemBody.idChild ? 'toggle-block__item text-left' : ''
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <div v-html="itemBody.data.html"></div>
                  <!-- <code>{{ itemBody.data.html }}</code> -->
                </div>
                <div
                  class="item-data w-100"
                  v-if="itemBody.type === 'table'"
                  :class="
                    itemBody.idChild ? 'toggle-block__item text-left' : 'my-3'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th
                          v-for="(itemTblTh, indexTblTh) in itemBody.data
                            .content[0]"
                          :key="indexTblTh"
                          class="border-bottom-0"
                        >
                          {{ itemTblTh }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          v-for="(itemTblTd, indexTblTd) in itemBody.data
                            .content[1]"
                          :key="indexTblTd"
                        >
                          {{ itemTblTd }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  class="item-data w-100"
                  v-if="itemBody.type === 'checklist'"
                  :class="
                    itemBody.idChild ? 'toggle-block__item text-left' : 'my-3'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <div
                    class="custom-control custom-checkbox"
                    v-for="(itemCheckbox, indexCheckbox) in itemBody.data.items"
                    :key="indexCheckbox"
                  >
                    <input
                      :id="'checkbox-' + itemBody.id + indexCheckbox"
                      type="checkbox"
                      :name="'checkbox-' + itemBody.id + indexCheckbox"
                      class="custom-control-input"
                      :checked="itemCheckbox.checked"
                    />
                    <label
                      :for="'checkbox-' + itemBody.id + indexCheckbox"
                      class="custom-control-label ml-1"
                    >
                      {{ itemCheckbox.text }}
                    </label>
                  </div>
                </div>
                <div
                  class="item-data w-100"
                  v-if="itemBody.type === 'warning'"
                  :class="
                    itemBody.idChild ? 'toggle-block__item text-left' : 'my-3'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <div class="w-100 bg-warning p-3 text-white">
                    <div class="w-100 font-weight-bold">
                      {{ itemBody.data.title }}
                    </div>
                    <hr />
                    <div class="w-100">
                      {{ itemBody.data.message }}
                    </div>
                  </div>
                </div>
                <div
                  class="item-data w-100"
                  v-if="itemBody.type === 'quote'"
                  :class="
                    itemBody.idChild ? 'toggle-block__item text-left' : 'my-3'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <blockquote
                    class="otro-blockquote"
                    :class="
                      itemBody.data.alignment === 'left'
                        ? 'text-left'
                        : 'text-right'
                    "
                  >
                    <span>
                      {{ itemBody.data.caption }}
                    </span>
                    {{ itemBody.data.text }}
                  </blockquote>
                </div>
                <div
                  class="item-data w-100"
                  v-if="itemBody.type === 'headerStyle'"
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                  :class="
                    itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'
                  "
                >
                  <div class="titleStyle" :class="itemBody.data.class">
                    <h1 class="dataInput">
                      {{ itemBody.data.text }}
                    </h1>
                  </div>
                </div>
                <div
                  class="item-data w-100"
                  v-if="itemBody.type === 'boxStylle'"
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                  :class="
                    itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'
                  "
                >
                  <div class="boxStyle" :class="itemBody.data.class">
                    <span
                      class="box-title px-2 border-0"
                      v-if="itemBody.data.title"
                      >{{ itemBody.data.title }}</span
                    >
                    <div
                      class="border-0 shadow-none bg-transparent h-auto form-control p-2 dataInput"
                    >
                      {{ itemBody.data.text }}
                    </div>
                  </div>
                </div>
                <div
                  class="item-data w-100"
                  v-if="itemBody.type === 'delimiter'"
                  :class="
                    itemBody.idChild ? 'toggle-block__item text-left' : 'my-3'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <div class="w-100 text-center ce-delimiter"></div>
                </div>
                <div
                  class="item-data w-100"
                  v-if="itemBody.type === 'image'"
                  :class="
                    itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <img :src="itemBody.data.file.url" alt="" width="auto" />
                  <h4 class="mt-2">{{ itemBody.data.caption }}</h4>
                </div>
                <div
                  class="item-data w-100"
                  v-if="itemBody.type === 'attaches'"
                  :class="
                    itemBody.idChild ? 'toggle-block__item text-left' : 'my-3'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <ViewPdf
                    v-bind:pageNumber="lastPagePDF"
                    v-bind:timeWatching="timeWatchingEmbed"
                    v-bind:idLast="idLastPDF"
                    v-bind:src="itemBody.data.file.url"
                    v-bind:idComponent="itemBody.id"
                    v-if="!loadDataNote"
                  />
                </div>
                <div
                  class="item-data w-100"
                  :class="
                    itemBody.idChild ? 'toggle-block__item text-left' : 'my-3'
                  "
                  v-if="
                    itemBody.type === 'embed' &&
                    itemBody.data.service === 'youtube'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <YoutubePlayer
                    v-bind:idElemYoutube="itemBody.id"
                    v-bind:idLastYoutube="idLastEmbed"
                    v-bind:timeWatching="timeWatchingEmbed"
                    v-bind:src="itemBody.data.source"
                    v-bind:heightPlayer="itemBody.data.height"
                    v-bind:widthPlayer="itemBody.data.width"
                  />
                  <h3 class="mt-2" v-if="itemBody.data.caption != null">
                    {{ itemBody.data.caption }}
                  </h3>
                </div>
                <div
                  class="item-data w-100"
                  :class="
                    itemBody.idChild ? 'toggle-block__item text-left' : 'my-3'
                  "
                  v-if="
                    itemBody.type === 'embed' &&
                    itemBody.data.service === 'vimeo'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <VimeoPlayer
                    v-bind:idElemVimeo="itemBody.id"
                    v-bind:idLastVimeo="idLastEmbed"
                    v-bind:timeWatching="timeWatchingEmbed"
                    v-bind:src="itemBody.data.embed"
                    v-bind:heightPlayer="itemBody.data.height"
                    v-bind:widthPlayer="itemBody.data.width"
                  />
                  <h3 class="mt-2" v-if="itemBody.data.caption != null">
                    {{ itemBody.data.caption }}
                  </h3>
                </div>
                <div
                  class="item-data w-100"
                  v-if="itemBody.type === 'anyButton'"
                  :class="
                    itemBody.tunes && itemBody.idChild
                      ? itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'toggle-block__item text-center'
                        : itemBody.tunes &&
                          itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'toggle-block__item text-right'
                        : 'toggle-block__item text-left'
                      : itemBody.tunes.anyTuneName.alignment === 'center'
                      ? 'text-center my-3'
                      : itemBody.tunes.anyTuneName.alignment === 'right'
                      ? 'text-right my-3'
                      : ' text-left my-3'
                  "
                  :id-child="itemBody.idChild"
                  :hidden="itemBody.showDisplay === true ? true : false"
                >
                  <a
                    v-if="itemBody.data.type === null"
                    :class="itemBody.data.style"
                    rel="nofollow noindex noreferrer"
                    :href="itemBody.data.link"
                  >
                    {{ itemBody.data.text }}
                  </a>
                  <div
                    class="w-100"
                    v-if="
                      itemBody.data.type !== null &&
                      (itemBody.data.style === 'roundButton' ||
                        itemBody.data.style === 'socialGlossySmooth' ||
                        itemBody.data.style === 'socialSquare')
                    "
                    :class="
                      itemBody.tunes && itemBody.idChild
                        ? itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'toggle-block__item text-center'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'toggle-block__item text-right'
                          : 'toggle-block__item text-left'
                        : itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'text-center my-3'
                        : itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'text-right my-3'
                        : ' text-left my-3'
                    "
                  >
                    <a
                      v-if="itemBody.data.twitter !== null"
                      :href="itemBody.data.twitter"
                      class="btn-social-circle btn-social-circle--twitter"
                      ><i class="fab fa-twitter"></i>
                    </a>
                    <a
                      v-if="itemBody.data.facebook !== null"
                      :href="itemBody.data.facebook"
                      class="btn-social-circle btn-social-circle--facebook"
                      ><i class="fab fa-facebook-f"></i>
                    </a>
                    <a
                      v-if="itemBody.data.pocket !== null"
                      :href="itemBody.data.pocket"
                      class="btn-social-circle btn-social-circle--pocket"
                      ><i class="fab fa-get-pocket"></i>
                    </a>
                    <a
                      v-if="itemBody.data.feedly !== null"
                      :href="itemBody.data.feedly"
                      class="btn-social-circle btn-social-circle--feedly"
                      ><i class="fas fa-rss"></i
                    ></a>
                  </div>
                  <div
                    class="w-100"
                    v-if="
                      itemBody.data.type !== null &&
                      (itemBody.data.style === 'socialGiza' ||
                        itemBody.data.style === 'socialSmartPhone')
                    "
                    :class="
                      itemBody.tunes && itemBody.idChild
                        ? itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'toggle-block__item text-center'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'toggle-block__item text-right'
                          : 'toggle-block__item text-left'
                        : itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'text-center my-3'
                        : itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'text-right my-3'
                        : ' text-left my-3'
                    "
                  >
                    <div
                      :class="
                        itemBody.data.style === 'socialGiza'
                          ? 'btn-social-giza'
                          : 'btn-social-phone'
                      "
                    >
                      <a
                        href="1"
                        :class="
                          itemBody.data.style === 'socialGiza'
                            ? 'btn-social-giza-twitter'
                            : 'btn-social-phone-twitter ml-0 mr-3'
                        "
                        v-if="itemBody.data.twitter !== null"
                      >
                        <span class="fa-stack">
                          <i
                            class="fas fa-stack-2x"
                            :class="
                              itemBody.data.style === 'socialGiza'
                                ? 'fa-certificate'
                                : 'fa-tablet-alt'
                            "
                          ></i>
                          <i class="fab fa-twitter fa-stack-1x"></i>
                        </span>
                      </a>
                      <a
                        href="3535"
                        :class="
                          itemBody.data.style === 'socialGiza'
                            ? 'btn-social-giza-facebook'
                            : 'btn-social-phone-facebook ml-0 mr-3'
                        "
                        v-if="itemBody.data.facebook !== null"
                      >
                        <span class="fa-stack">
                          <i
                            class="fas fa-stack-2x"
                            :class="
                              itemBody.data.style === 'socialGiza'
                                ? 'fa-certificate'
                                : 'fa-tablet-alt'
                            "
                          ></i>
                          <i class="fab fa-facebook-f fa-stack-1x"></i>
                        </span>
                      </a>
                      <a
                        href="5467"
                        :class="
                          itemBody.data.style === 'socialGiza'
                            ? 'btn-social-giza-pocket'
                            : 'btn-social-phone-pocket ml-0 mr-3'
                        "
                        v-if="itemBody.data.pocket !== null"
                      >
                        <span class="fa-stack">
                          <i
                            class="fas fa-stack-2x"
                            :class="
                              itemBody.data.style === 'socialGiza'
                                ? 'fa-certificate'
                                : 'fa-tablet-alt'
                            "
                          ></i>
                          <i class="fab fa-get-pocket fa-stack-1x"></i>
                        </span>
                      </a>
                      <a
                        href="1"
                        :class="
                          itemBody.data.style === 'socialGiza'
                            ? 'btn-social-giza-feedly'
                            : 'btn-social-phone-feedly ml-0 mr-3'
                        "
                        v-if="itemBody.data.feedly !== null"
                      >
                        <span class="fa-stack">
                          <i
                            class="fas fa-stack-2x"
                            :class="
                              itemBody.data.style === 'socialGiza'
                                ? 'fa-certificate'
                                : 'fa-tablet-alt'
                            "
                          ></i>
                          <i class="fas fa-rss fa-stack-1x"></i>
                        </span>
                      </a>
                    </div>
                  </div>
                  <div
                    class="w-100"
                    v-if="
                      itemBody.data.type !== null &&
                      (itemBody.data.style === 'socialFlat' ||
                        itemBody.data.style === 'socialIsometric')
                    "
                    :class="
                      itemBody.tunes && itemBody.idChild
                        ? itemBody.tunes.anyTuneName.alignment === 'center'
                          ? 'toggle-block__item text-center'
                          : itemBody.tunes &&
                            itemBody.tunes.anyTuneName.alignment === 'right'
                          ? 'toggle-block__item text-right'
                          : 'toggle-block__item text-left'
                        : itemBody.tunes.anyTuneName.alignment === 'center'
                        ? 'text-center my-3'
                        : itemBody.tunes.anyTuneName.alignment === 'right'
                        ? 'text-right my-3'
                        : ' text-left my-3'
                    "
                  >
                    <div>
                      <a
                        href="1"
                        :class="
                          itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat'
                            : 'btn-social-isometric'
                        "
                      >
                        <span
                          :class="
                            itemBody.data.style === 'socialFlat'
                              ? 'btn-social-flat-icon btn-social-isometric-icon--twitter'
                              : 'btn-social-isometric-icon btn-social-isometric-icon--twitter'
                          "
                        >
                          <i class="fab fa-twitter"></i>
                        </span>
                        <span
                          :class="
                            itemBody.data.style === 'socialFlat'
                              ? 'btn-social-flat-text'
                              : 'btn-social-isometric-text'
                          "
                          >TWEET</span
                        >
                      </a>
                    </div>
                    <div>
                      <a
                        href="1"
                        :class="
                          itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat'
                            : 'btn-social-isometric'
                        "
                      >
                        <span
                          :class="
                            itemBody.data.style === 'socialFlat'
                              ? 'btn-social-flat-icon btn-social-isometric-icon--facebook'
                              : 'btn-social-isometric-icon btn-social-isometric-icon--facebook'
                          "
                        >
                          <i class="fab fa-facebook"></i>
                        </span>
                        <span
                          :class="
                            itemBody.data.style === 'socialFlat'
                              ? 'btn-social-flat-text'
                              : 'btn-social-isometric-text'
                          "
                          >TWEET</span
                        >
                      </a>
                    </div>
                    <div>
                      <a
                        href="1"
                        :class="
                          itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat'
                            : 'btn-social-isometric'
                        "
                      >
                        <span
                          :class="
                            itemBody.data.style === 'socialFlat'
                              ? 'btn-social-flat-icon btn-social-isometric-icon--pocket'
                              : 'btn-social-isometric-icon btn-social-isometric-icon--pocket'
                          "
                        >
                          <i class="fab fa-get-pocket"></i>
                        </span>
                        <span
                          :class="
                            itemBody.data.style === 'socialFlat'
                              ? 'btn-social-flat-text'
                              : 'btn-social-isometric-text'
                          "
                          >TWEET</span
                        >
                      </a>
                    </div>
                    <div>
                      <a
                        href="1"
                        :class="
                          itemBody.data.style === 'socialFlat'
                            ? 'btn-social-flat'
                            : 'btn-social-isometric'
                        "
                      >
                        <span
                          :class="
                            itemBody.data.style === 'socialFlat'
                              ? 'btn-social-flat-icon btn-social-isometric-icon--feedly'
                              : 'btn-social-isometric-icon btn-social-isometric-icon--feedly'
                          "
                        >
                          <i class="fas fa-rss"></i>
                        </span>
                        <span
                          :class="
                            itemBody.data.style === 'socialFlat'
                              ? 'btn-social-flat-text'
                              : 'btn-social-isometric-text'
                          "
                          >TWEET</span
                        >
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </b-card-body>
            <!-- </b-collapse> -->
          </div>
        </div>
        <div
          class="card-content pt-3 row align-items-center justify-content-center w-100 content-btn"
        >
          <button
            v-on:click="returnPage()"
            class="float-right button-back-dashboard back-btn"
          >
            戻る
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { Constants } from "../../utils/constants";
import ViewPdf from "../../components/viewPdf/index.vue";
import YoutubePlayer from "../../components/youtubePlayer/index.vue";
import VimeoPlayer from "../../components/vimeoPlayer";
import Vue from "vue";

Vue.component("v-style", {
  render: function (createElement) {
    return createElement("style", this.$slots.default);
  },
});
export default {
  name: "previewPage",
  components: {
    ViewPdf,
    YoutubePlayer,
    VimeoPlayer,
  },
  data() {
    return {
      page: localStorage.getItem(Constants.PAGE_PREVIEW)
        ? JSON.parse(localStorage.getItem(Constants.PAGE_PREVIEW))
        : null,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
    };
  },
  created() {
    // this.page.js;
    // let externalScript = document.createElement("script");
    // externalScript.setAttribute(
    //   "src",
    //   "https://www.example-of-external-script.com/script.js"
    // );
    // externalScript.innerHTML = this.page.js;
    // document.head.appendChild(externalScript);
  },
  mounted() {
    if (
      this.$route.fullPath.includes("fixed-page/preview") &&
      this.page.type == 6
    ) {
      let externalScript = document.createElement("script");
      externalScript.innerHTML = this.page.js;
      document.head.appendChild(externalScript);
    }
    if (localStorage.getItem(Constants.PAGE_PREVIEW)) {
      this.page = JSON.parse(localStorage.getItem(Constants.PAGE_PREVIEW));
      this.page.description.map((item) => {
        const blocksData = item.description.blocks;
        blocksData.map((itemBlock) => {
          if (itemBlock.type === "toggle") {
            const indexBlockToggle = blocksData.indexOf(itemBlock);
            if (itemBlock.data.child.length >= itemBlock.data.items) return;
            for (let i = 1; i <= itemBlock.data.items; i++) {
              itemBlock.data.child.push(blocksData[indexBlockToggle + i]);
              if (itemBlock.data.status === "closed")
                blocksData[indexBlockToggle + i].showDisplay = true;
              blocksData[indexBlockToggle + i].idChild = itemBlock.id;
            }
          }
        });
      });
    }
  },
  watch: {
    $route() {
      if (localStorage.getItem(Constants.PREVIEW_COLUMN_PAGE)) {
        this.optionColumn = JSON.parse(
          localStorage.getItem(Constants.PREVIEW_COLUMN_PAGE)
        );
      }
      if (localStorage.getItem(Constants.PAGE_PREVIEW)) {
        this.page = JSON.parse(localStorage.getItem(Constants.PAGE_PREVIEW));
      }
    },
  },
  computed: {},
  methods: {
    returnPage() {
      if (this.page.id) {
        this.$router.push({
          name: this.$route.params.shopId
            ? "editFixedPage"
            : "editFixedPage domain",
          params: { shopId: this.shop_id, id: this.page.id },
        });
      } else {
        this.$router.push({
          name: this.$route.params.shopId
            ? "createFixedPage"
            : "createFixedPage domain",
        });
      }
    },
    toggleBlock(item) {
      const value = item.data.status === "closed";
      const children = document.querySelectorAll(`div[id-child="${item.id}"]`);
      const { length } = children;

      if (length > 0) {
        for (let i = 0; i < length; i++) {
          children[i].hidden = !value;
          if (children[i].showDisplay !== undefined)
            children[i].showDisplay = !value;
        }
      }
      if (item.data.status === "open") {
        item.data.status = "closed";
      } else {
        item.data.status = "open";
      }
    },
  },
};
</script>
<style lang="scss">
.ql-editor.previewspage {
  min-height: unset !important;
}
.page-preview {
  min-height: calc(100vh - 216px);
  border: none;
  .toggle-block__item {
    max-width: unset;
  }
}
.page-content {
  min-height: calc(100vh - 216px);
}
.content-btn {
  // bottom: 5%;
  padding-bottom: 10px;
}
.footer {
  bottom: 0;
  min-height: 70px;
}
</style>
